import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/f173253cd5deaab49a1e22894bb5228ce445f9a5e23ac756041d60ec99bb0a41/navBarModifier';
import SharedProviders_1_0 from './extensions/893b350a4e5f0cefae9dca2bbb107950bbd236ed77bc0b51242afc7ac59dbe90/providers';
import SharedProviders_2_0 from './extensions/software-houses/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0],
})
export class SharedExtensionsModule {}
